<template>
  <div class="join__container">
    <div class="page__title scrollObj">join us</div>
    <div class="join__main-body__container">
      <div class="join__item-container" v-for="(job, index) in jobs" :key="index">
        <div class="animation__line scrollObj"></div>
        <div class="item__area-title scrollObj" ref="titles">{{ job.title.en }}</div>
        <div class="item__main-content">
          <div
            class="item__job__row"
            v-for="(item, i) in job.lists"
            :key="i"
            :class="{
              pcHover: !$bowser.mobile
            }"
          >
            <div class="row__inner">
              <div class="animation__line scrollObj" v-if="i !== 0 || $bowser.mobile"></div>
              <div class="job__title scrollObj">{{ item.name.en }} {{ item.name.cn }}</div>
              <div class="job__main-text">
                <div class="job__main-content scrollObj" v-html="item.content"></div>
                <div class="job__detail-info scrollObj">
                  {{ job.title.en.split(" ")["0"] }} | {{ job.title.cn }}
                </div>
              </div>
            </div>
          </div>
          <div class="item__job__row -row__for__mail" v-if="index === jobs.length - 1">
            <div class="row__inner">
              <div class="animation__line scrollObj"></div>
              <div class="job__title scrollObj">To apply&nbsp;&nbsp;<b>应聘渠道</b></div>
              <div class="job__main-text scrollObj">
                <p>
                  有意者请将简历及个人作品发至邮箱：<a href="mailto:xialin@facedesign.cn"
                    >xialin@facedesign.cn</a
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import events from '@/utils/eventBus';
import { jobs as getJobs } from '@/api/data';

export default {
  name: 'views-join',
  data() {
    return {
      jobs: [],
      // jobs: [
      //   {
      //     area: 'Shanghai Region',
      //     lists: [
      //       {
      //         title: {
      //           cn: '主案设计师',
      //           en: 'Chief Designer',
      //         },
      //         summary:
      //           '<p>五年以上装饰设计经验，施工图制作规范、熟练，主持过大中型项目的设计深化及现场协调工作；</p><p>正规院校建筑学或室内设计专业本科以上学历，同时具有较强的设计项目管理能力，有一定的售楼处，住宅，商办等画图经验；</p><p>有能力独立完成从方案到施工图的全部过程，指导现场施工，负责设计项目管理，具有团队合作精神；同时负责与客户沟通及合同控制的基本过程；</p><p>熟练运用各种设计绘图等相关专业软件，必须有较强的手绘功底。</p>',
      //         location: {
      //           cn: '上海',
      //           en: 'Shanghai',
      //         },
      //       },
      //       {
      //         title: {
      //           cn: '效果图设计',
      //           en: 'Rendering Designer',
      //         },
      //         summary:
      //           '<p>室内设计、装饰设计或美术相关专业大专以上学历；</p><p>熟练使用主流设计软件，掌握各种渲染及表现技法，能独立而高效地完成高质量的电脑效果图；</p><p>熟悉室内设计，对方案有较好的领悟力和深化表现能力，能够准确的把握设计师意图，快速表现设计效果；</p><p>二年或以上效果图制作工作经验，面试需附作品；</p><p>有工作责任心及团队合作精神，可承受工作压力。</p>',
      //         location: {
      //           cn: '上海',
      //           en: 'Shanghai',
      //         },
      //       },
      //       {
      //         title: {
      //           cn: '助理设计师',
      //           en: 'Design Assistant',
      //         },
      //         summary:
      //           '<p>拥有大型地产项目室内设计经验，方案能力强，能按总监设计意图完成整体方案。对高端酒店、办公、住宅项目的设计有自己独到的见解；</p><p>有较强的资源整合能力、表现能力；熟练使用（3D，SU，PPT，PS）、抗压能力、沟通能力。有领导团队经验优先。</p>',
      //         location: {
      //           cn: '上海',
      //           en: 'Shanghai',
      //         },
      //       },
      //     ],
      //   },
      //   {
      //     area: 'Chengdu Region',
      //     lists: [
      //       {
      //         title: {
      //           cn: '机电设计师',
      //           en: 'Electromechanical Designer',
      //         },
      //         summary:
      //           '<p>电气设计类专业，本科以上学历；</p><p>熟悉室内及装饰暖通设计（强弱电）及相应规范；</p><p>熟练操作相关绘图软件，专业基础知识牢固，熟悉本专业各项规范、规程等；</p><p>敬业踏实、认真负责，细心严谨，有良好的职业素质和团队精神及沟通协调能力及施工现场服务；</p><p>负责强弱电专业图纸设计及施工深化；</p><p>负责强弱电方案等工程招标文件的技术部分和工程量预算和结算工作；</p><p>做好本专业有关技术资料的整理工作；</p><p>完成主管领导交办的其他工作。</p><p>心理素质过硬，有一定的抗压能力</p>',
      //         location: {
      //           cn: '成都',
      //           en: 'Chengdu',
      //         },
      //       },
      //     ],
      //   },
      // ],
    };
  },
  async mounted() {
    const { data: jobs } = await getJobs();
    this.jobs = jobs.lists;
    this.$nextTick(() => {
      const { titles } = this.$refs;
      titles.forEach((item) => {
        const titleHtmlEle = item;
        titleHtmlEle.style.cssText = `top:${
          document.querySelector('.header__container').offsetHeight
        }px`;
      });
      this.pageInit();
      events.$emit('pageMounted');
    });
  },
};
</script>

<style></style>
